<template lang="pug">
    b-modal#modal-create-job(ref="modal-create-job", centered, :title="(job.id ? 'Modifier': 'Ajouter') + ' un métier'", @ok="create")
        div(v-if="isLoadingJob")
            .text-center
                .spinner-border.text-primary(role="status")
                    span.sr-only Chargement...
                br
                | Chargement des métiers...
        validation-observer(v-else, ref="formCreateJob")
                b-row
                    b-col(cols="12")
                        b-form-group(label="Libellé", label-for="label")
                            validation-provider(#default="{ errors }", name="Label", rules="required")
                                b-form-input#label(v-model="job.label", :state="errors.length > 0 ? false:null", placeholder="Renseigner un libellé")
                                small.text-danger {{ errors[0] }}
        template(#modal-footer="{ ok, cancel}")
            .w-100
                b-button.float-right(v-ripple.400="'rgba(255, 255, 255, 0.15)'", variant="primary", @click="ok()") {{(job.id ? 'Modifier': 'Ajouter')}}
                b-button.float-right.mr-1(v-ripple.400="'rgba(255, 255, 255, 0.15)'", variant="outline-primary", @click="cancel()") Annuler

</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize
} from 'vee-validate'
import VSwatches from 'vue-swatches'
import { required, url, email } from '@validations'
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import 'vue-swatches/dist/vue-swatches.css'
configure({
  // Generates an English message locale generator
  generateMessage: localize('fr', {
    messages: {
      url: 'Ce champ doit être une URL',
      required: 'Ce champ est requis',
      email: 'Ce champ doit être un email'
    }
  })
})
localize('fr')

export default {
  data () {
    return {
      required,
      url,
      email,
      job: {
        label: '',
        workspaceId: this.$store.getters.workspaceSelected
      }
    }
  },
  methods: {
    popupCreateJobEvent (id) {
        this.fetchJob(id).then(res => {
          this.job = res
        })
      
    },
    create (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.formCreateJob.validate().then(success1 => {
        if (success1) {
           if (this.job.id) this.updateJob(this.job)
            else this.createJob(this.job)
          this.$nextTick(() => {
            this.$refs['modal-create-job'].toggle('#toggle-btn')
          })
        }
      })
    },

    ...mapActions(['createJob', 'updateJob', 'fetchJob'])
  },
  computed: {
    ...mapGetters(['isLoadingJob'])
  },
  watch: {
    createdDataJob: function () {
      this.initializeForm()
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    VSwatches
  },
  directives: {
    Ripple
  }
}
</script>

<style></style>
